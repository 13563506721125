import React from 'react'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { FaPlay } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'

import Meta from 'components/meta'
import WrapperImage from 'components/wrapper-image'

interface Props {
  location: Location
}

const Index = ({ location }: Props) => {
  function triggerRootClose() {
    document?.body?.click()
  }
  return (
    <WrapperImage background="yomo-portal-bg.jpg" backgroundClass="o-50" variant="cover" location={location}>
      <Meta title="Welcome" />
      <div id="portal" className="h-100 d-flex flex-column align-items-center justify-content-center">
        <Container fluid="md" className="h-100 d-flex flex-column align-items-center justify-content-center ">
          <div className="w-100 p-3 mb-3 mb-lg-5 bg-body-dim text-center">
            <div className="fs-3">YOUR OCEAN, MY OCEAN</div>
            <div className="display-6">YO~MO</div>
          </div>

          <div className="w-100 p-3 mb-3 mb-lg-5 bg-body-dim text-center">
            <div className="px-md-3 px-lg-5">
              <div className="fs-4">WE ARE INSPIRED</div>
              <div className="fs-5 pb-3">
                by the natural beauty of our planet's oceans and coastlines, including aesthetics of human
                involvement
              </div>
              <div className="fs-4">WE MUST RESPOND</div>
              <div className="fs-5 pb-3">
                to the existential threat posed by rapidly escalating degradation of ocean systems
              </div>
              <div className="fs-4">WE EMBRACE</div>
              <div className="fs-5">
                multi-dimensional approaches to a systems view of life, in which humans are part of a larger, living
                whole
              </div>
            </div>
          </div>

          <div className="w-100 p-3 mb-3 mb-lg-5 bg-body-dim text-center">
            <div className="px-md-3 px-lg-5">
              <div className="fs-4 pb-2">WELCOME</div>
              <div className="fs-5 pb-3">
                You've found the preview release of an online art project from{' '}
                <span className="text-nowrap">Eco ArtLab,</span> currently in active development.
              </div>

              <div className="fs-5 pb-3">
                We're happy you're here! As one of the first visitors, you now have early access to exciting new work
                being created by an international collective of artists and designers. You'll find lots of wonderful
                material on the site, with more to come. Soon we'll be unveiling an engaging game-like interface in
                which you'll create your own story by navigating dance, theatre and music segments that respond to the
                natural beauty of oceans and coastlines, as well as to detrimental human impacts on ocean ecosystems.
              </div>

              <div className="fs-5 pb-4">
                Our team regularly serves up fresh YO~MO content and interactive features. Follow{' '}
                <span className="text-nowrap">
                  <a href="https://www.instagram.com/artlab.eco/">Eco ArtLab</a>
                </span>{' '}
                on Instagram to keep up with all the developments.
              </div>

              <Button className="mb-3" variant="outline-primary" size="lg" href={`/node/`}>
                Enter YO~MO
              </Button>

              <div className="mb-3">
                <OverlayTrigger
                  rootClose
                  placement="bottom"
                  overlay={<Tooltip id={'tooltip-instagram'}>Eco ArtLab on Instagram</Tooltip>}
                >
                  <Button href="https://www.instagram.com/artlab.eco/" variant="icon" onClick={triggerRootClose}>
                    <FaInstagram size={40} />
                  </Button>
                </OverlayTrigger>
              </div>

            </div>
          </div>
        </Container>
      </div>
    </WrapperImage>
  )
}

export default Index
